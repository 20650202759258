import React, {useEffect} from 'react';
// import './About.css';
import styles from './About.module.css';
import abtim from './images/ud1.png';

const About = () => {

  useEffect(()=>{
    const about = document.getElementById('about')
    about.scrollIntoView({behavior: 'smooth'})
  }, [])


  return (
    <div className={styles.aboutpg} id="about">
      <img src={abtim} alt=""  className={styles.abtimg}/>
      <p className={styles.abtq}>So long as you do not achieve social liberty, whatever freedom is provided by the law is of no avail to you.</p><p className={styles.abtq1} > -Dr. B.R.Ambedkar</p>
      <div className={styles.box}>
      <p className={styles.paraa} >
        Sare Foundation stands for - <p>Social And Regional Empowerment </p>
      </p>
      </div>
      <div className={styles.box}>
        <p className={styles.para}>
          <h3 className={styles.heading}>About</h3>
          The Sare Foundation is a non-profit organization dedicated to making a positive impact in
          the lives of marginalized communities in India. We believe that every individual deserves equal opportunities to live
          a dignified life, regardless of their social, economic, or cultural background.</p>
      </div>
     
     
      {/* Mission paragraph */}
      <div className={styles.box}>
        <p className={styles.para}>
          <h3 className={styles.heading}>Our Mission</h3>
          Our mission is to empower marginalized communities through education, skill development, and 
          community engagement. We work with street children, women, and the LGBTQ+ community to provide 
          them with the resources and support they need to overcome poverty, discrimination, and violence.

        </p>
      </div>
            {/* Vision paragraph */}

            <div className={styles.box}>
              <p className={styles.para}>
                <h3 className={styles.heading}>Our Vision</h3>
               <ul className={styles.sub_heading}>
               <li>Empowering women and girls to reach their full potential: This could involve education, economic opportunities, Healthcare, and Leadership Development.</li>
              <li>Building a Society where children thrive: This could focus on education, safety, health, and nurturing supportive environments.</li>
              <li>Championing LGBTQI+ community rights and inclusion: This could involve advocacy, community building, and promoting acceptance and understanding.</li>
               </ul>
              </p>

            </div>

    </div>

  )
}

export default About;