import React from 'react';
import styles from'./Footer.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { FiYoutube } from "react-icons/fi";
import { ImWhatsapp } from "react-icons/im";
import { SlSocialInstagram } from "react-icons/sl";
import mwncd from './imgs/mwncd.png';
import maha_police from './imgs/maha_police.png';
import smile from './imgs/smile.png';
import child_helpline from './imgs/child_helpline.png';
import break_line from '../images/line1.png'
const Footer = () => {
    const navigate = useNavigate()
    function goToContact() {
        navigate("/Contact")
    }
    function gotoDonate(){
        navigate('/Donate')
    }
    
    return (

        <div className={styles.footer}>
            <div className={styles.footer_heading}>
                <h3>If you want to speak to us about leaving a legacy please get in touch</h3>
                <div className={styles.btn_container}>
                    <button className={styles.contact_btn} onClick={goToContact}>Contact</button>
                </div>
            </div>
             <h3 className={styles.partner_heading}>Our Partners</h3>
             <div className={styles.footer_middle}>
                
                   <a href="https://wcd.nic.in/" target='_blank'><img src={mwncd} className={styles.partner_img1}/></a> 
                    <a href="https://amravaticitypolice.gov.in/" target='_blank'> <img src={maha_police} className={styles.partner_img2}/></a>
                   <a href=""><img src={smile} className={styles.partner_img3}/></a> 
                  <a href="https://www.childlineindia.org/a/report/case?gad_source=1&gclid=CjwKCAiAhJWsBhAaEiwAmrNyq3k4Y_QzoS0phQvKseab4_PrBNtddrVYZgyjg1tpERY-sfphJr97jBoC8boQAvD_BwE" target='_blank'> <img src={child_helpline} className={styles.partner_img4}/></a> 
                
            </div>
            
            <div className={styles.footer_bottom}>
                <div className={styles.social_media}>
                <ul>
                        <li>
                            <a href='https://www.instagram.com/sarefoundation/' target='_blank'>
                                <SlSocialInstagram className={styles.instagram_f} />
                            </a>
                        </li>
                        <li>
                            <a href="https://wa.me/+919075222500"  target='_blank'>
                                <ImWhatsapp className={styles.whatapp_f} />
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.youtube.com/@sarefoundationngo4820"
                                target="_blank">
                                <FiYoutube className={styles.youtube_f} />
                            </a>
                        </li>
                    </ul>
                    <button className={styles.contact_btn1}  onClick={goToContact}>Contact</button>
                </div>
             
               
               <img src={break_line} alt="line" className={styles.break_line}></img>
                 
                  <h1 className={styles.bottom_heading}>Useful Links</h1>
               
                 <div className={styles.bottom_links}>
                    <div className={styles.bottom_links1}>
                <ul>
                    
                    <li>
                        <Link to='/about' >About</Link>
                    </li>
                    <li>
                        <Link to='/reports'  >Reports</Link>
                    </li>
                </ul>
                </div>
                
                <div className={styles.bottom_links2}>
                    <ul>
                        <li>
                            <Link to='/terms_and_conditions' className='link2'  >Terms and Conditions</Link>
                        </li>
                        <li>
                            <Link to='/refund_policy' className='link2' >Refund Policy</Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <button className={styles.contact_btn2} onClick={gotoDonate}>Donate Now</button>
                </div>
                </div>

                <div className={styles.copy_right}> 
                    <h6>Sare Foundation is  Reg. under
        Indian Socities Act 1816, NGO Darpan, section 12(A), and section 80(G)
        of Income Tax Act, CSR of Ministry of Corporate Affairs
        <p>@{new Date().getFullYear()} sarefoundation. All Rights Reserved</p>
        </h6>
                </div> 
            </div> 

        </div>
    )
}

export default Footer;