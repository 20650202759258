import React from "react";
import "./Home.css";
import {  Link} from "react-router-dom";
import CarouselPage from "./CarouselPage";
import CardList from "./CardList";
// import side_img from "./images/side_img.png";
export const Home = () => {
  // const Navigate = useNavigate();
  // function gotoJoinPage() {
  //   Link("https://forms.gle/n3eWoQHvSiRB69xv9");
  // }
  return (
    <div className="head-text">
      {/* <div className="register_line">
        <marquee behavior="scroll" direction="left"> Reg. under
        Indian Socities Act 1861, NGO Darpan, section 12(A), and section 80(G)
        of Income Tax Act, CSR of Ministry of Corporate Affairs</marquee>
    
      </div> */}
      <CarouselPage />

     <div className="jcontainer">
     <div className="joinus-bg">
        {/* <div className="side_img">
          <img src={side_img} className="imgsd" />
        </div> */}
        <div className="joinus-text-para">
          <h3 className="joinus-text">JEE / NEET PRE TRAINING SCHOLARSHIP EXAMINATION 2024-2025</h3>
          <div className="btn_part">
            <button className="join-btn">
<a href="https://forms.gle/n3eWoQHvSiRB69xv9" target="_blank"> Registration Form</a>           
            </button>
          </div>
        </div>
      </div>
     </div>
      <CardList />
    </div>
  );
};
